import { getPlatformLink } from '@firi/shared';
import { Chip } from '@firi/ui-web';
import { t } from '@lingui/macro';
import ContentWrapper from 'components/ContentWrapper';
import { Grid, GridItem } from 'components/Grid';
import { MitIdLoginButton } from 'components/MitIdLoginButton';
import SanityContent from 'components/SanityContent';
import { Typography } from 'components/Typography';
import { sendGTMEvent } from 'helpers/sendGTMEvent';
import { useEffect, useRef } from 'react';
import { DanishLandingHero as DanishLandingHeroType } from 'types/schema';

type Props = {
  node: DanishLandingHeroType;
};

/**
 * Deprecated -> use LandingHeroV2
 */
export const DanishLandingHero = ({ node }: Props) => {
  const ref = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const timerId = setTimeout(() => {
      ref.current?.play();
    }, 2000);
    return () => {
      clearTimeout(timerId);
    };
  }, []);

  return (
    <section className="py-12 md:py-0 bg-firi-blue">
      <ContentWrapper className="lg:px-12">
        <Grid>
          <GridItem className="flex flex-col col-span-12 md:col-span-8 justify-center">
            <div className="flex items-center space-x-2 mb-4">
              <Chip variant="blue" size="sm" icon="check-circle" />
              <Typography size="md" color="white">
                {node.overHeader}
              </Typography>
            </div>
            <div className="mb-6">
              <Typography size="3xl" color="white">
                {node.header}
              </Typography>
            </div>
            <div className="flex">
              <div className="lg:w-1/2">
                <div className="mb-6">
                  <Typography size="md" color="white">
                    {node.subtitle}
                  </Typography>
                </div>
                <div>
                  {node?.signupButton?.icon === 'mit-id' ? (
                    <a
                      href={getPlatformLink({ link: 'signup' })}
                      aria-label={t`To sign up with MitId`}
                      onClick={() => {
                        sendGTMEvent({
                          event_action: `Signup clicks`,
                          event_label: 'Signup with MitId',
                          event_category: 'Identification',
                          signup_method: 'mitid',
                        });
                      }}
                    >
                      <MitIdLoginButton variant="white" />
                    </a>
                  ) : (
                    <SanityContent blocks={node.signupButton} />
                  )}
                </div>
              </div>
            </div>
          </GridItem>
          <GridItem className="col-span-12 lg:col-span-4 flex lg:justify-start justify-center">
            <img src="/images/app-invest.png" alt="Firi app Invest screen" />
          </GridItem>
        </Grid>
      </ContentWrapper>
    </section>
  );
};
